import React, { useState, useEffect, useMemo } from "react";
import { Button, Modal } from "react-bootstrap";

import {
  FaList,
  FaImages,
  FaTh,
  FaPaintBrush,
  FaStoreAlt,
  FaLaptop,
  FaBookOpen,
  FaUsers,
} from "react-icons/fa";
import {
  AiOutlineLogout,
  AiOutlineProfile,
  AiOutlineUnlock,
} from "react-icons/ai";
import { useDispatch } from "react-redux";
import { AiOutlineMenuFold } from "react-icons/ai";
import { PiSquaresFourBold } from "react-icons/pi";
import { PiChatsCircleBold } from "react-icons/pi";
import { RiSettings2Line } from "react-icons/ri";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import "./Sidebar.css";
import Notification from "../components/sidebar/notification";
import Cookies from "js-cookie";
import { profileInfo } from "../redux/action";
import ControlledOpenSelect from "../components/sidebar/businesses";
import ProfileControlDrop from "../components/sidebar/profileDrop";
import { setSellerId, setCurrentBusiness } from "../redux/action";
import { UserapiClient } from "../components/userManagementApiclient";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import logo from "../assets/Empty bg.png";
import smaakerlogo from "../assets/smaakerlogo.svg";
import paylooplogo from "../assets/paylooplogo.svg";
import ChatBot from "../components/chatbot";
import { apiClient } from "../components/apiClient";
const Sidebar = ({
  userRoutes,
  userProfile,
  Profileinfo,
  currentBusiness,
  accessToken,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const businessId =
  const userId = userProfile?.userId;
  const UserClient = UserapiClient(accessToken);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    localStorage.getItem("selectedMenuItem") || ""
  );
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [sellerIdDispatched, setSellerIdDispatched] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [businessData, setBusinessData] = useState([]);
  const logoImg = require(".././assets/logo192.png");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isUserAdmin = useMemo(() => {
    if (!currentBusiness || !userProfile) return false;
    return currentBusiness.users.some(
      (user) =>
        user.userId === userProfile.userId &&
        user.role.toLowerCase() === "admin"
    );
  }, [currentBusiness, userProfile]);

  const filteredRoutes = useMemo(() => {
    return userRoutes?.filter((route) => route.name !== "Users" || isUserAdmin);
  }, [userRoutes, isUserAdmin]);

  useEffect(() => {
    console.log("Current business:", currentBusiness);
    console.log("Is user admin:", isUserAdmin);
    console.log("Filtered routes:", filteredRoutes);
  }, [currentBusiness, isUserAdmin, filteredRoutes]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleIconClick = (path) => {
    navigate(path);
    if (isMobile) {
      setIsSidebarOpen(true);
    }
    setSelectedMenuItem(path);
    localStorage.setItem("selectedMenuItem", path);
  };
  useEffect(() => {
    localStorage.setItem("getAllBusiness", JSON.stringify(businessData));
  }, [businessData]);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const iconMapping = {
    FaList,
    FaImages,
    FaTh,
    FaPaintBrush,
    FaStoreAlt,
    FaLaptop,
    FaBookOpen,
    FaUsers,
  };

  const clearCognitoCookies = () => {
    const allCookies = Cookies.get();
    for (const cookieName in allCookies) {
      if (cookieName.startsWith("CognitoIdentityServiceProvider")) {
        Cookies.remove(cookieName);
        localStorage.setItem("businesssName", null);
        navigate("/");
        window.location.reload();
      }
    }
  };

  const handleLogout = () => {
    clearCognitoCookies();
    localStorage.removeItem("selectedMenuItem");
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth <= 768);

      if (screenWidth <= 768) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const currentPath = location.pathname;
    setSelectedMenuItem(
      currentPath === "/layouts-view"
        ? "/layouts"
        : currentPath === "/displayPreview"
        ? "/displays"
        : currentPath === "/"
        ? "/dashboard"
        : currentPath
    );
  }, [location.pathname]);

  const getProfille = async () => {
    try {
      const response = await UserClient.get(`/getprofile/${userId}`);
      //console.log("vd", response.data);
      dispatch(profileInfo(response.data));
    } catch {
      console.log("hihi");
    }
  };

  const [chatMenuState, setChatMenuState] = useState(false);

  const clickToOpenChatbot = () => {
    setChatMenuState(!chatMenuState);
  };

  useEffect(() => {
    const getBusiness = async () => {
      try {
        const response = await UserClient.get(`/businesses/${userId}`);
        const businesses = response.data.businesses;
        setBusinessData(businesses);

        getProfille();

        const lastUsedSellerId = Cookies.get(`${userId}lastusedsellerId`);
        const lastUserBusinessInfo = Cookies.get(
          `${userId}lastusedbusinessDetails`
        );

        if (lastUsedSellerId && !sellerIdDispatched && lastUserBusinessInfo) {
          const lastUsedBusiness = businesses.find(
            (business) => business.sellerId === lastUsedSellerId
          );
          if (lastUsedBusiness) {
            dispatch(setSellerId(lastUsedSellerId));
            dispatch(setCurrentBusiness(lastUsedBusiness));
          } else {
            // If the last used business is not found, fall back to the first business
            dispatch(setSellerId(businesses[0]?.sellerId));
            dispatch(setCurrentBusiness(businesses[0]));
          }
        } else if (!sellerIdDispatched) {
          dispatch(setSellerId(businesses[0]?.sellerId));
          dispatch(setCurrentBusiness(businesses[0]));
        }
        setSellerIdDispatched(true);
      } catch (error) {
        console.log("error", error);
      }
    };

    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    getBusiness();

    return () => clearTimeout(loadingTimeout);
  }, [userId, dispatch, sellerIdDispatched]);

  const firstBusiness = {
    sellerId: userProfile?.sellerId,
  };

  // New function to handle direct access to display preview
  const handleDirectDisplayPreview = async () => {
    const displayId = location.pathname.split("/").pop();
    if (displayId && location.pathname.includes("/displayPreview")) {
      try {
        const response = await apiClient(accessToken).get(
          `display/details/${displayId}`
        );
        const displayData = response.data.data;
        const displaySellerId = displayData.sellerId;

        const businessResponse = await UserClient.get(`/businesses/${userId}`);
        const businesses = businessResponse.data.businesses;
        const currentBusiness = businesses.find(
          (business) => business.sellerId === displaySellerId
        );

        if (currentBusiness) {
          dispatch(setSellerId(currentBusiness.sellerId));
          dispatch(setCurrentBusiness(currentBusiness));
          localStorage.setItem("currentbusinesssid", currentBusiness.id);
        }
      } catch (error) {
        console.error("Error handling direct display preview access:", error);
      }
    }
  };

  useEffect(() => {
    handleDirectDisplayPreview();
  }, [location.pathname]);

  const defaultUser = {
    userName: Profileinfo?.name,
    userEmail: Profileinfo?.email,
    userRole: "Admin",
    userProfile:
      Profileinfo?.fileLocation ||
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1200px-Default_pfp.svg.png",
  };

  const profileFooterMenuItems = [
    {
      id: 1,
      icon: (
        <AiOutlineProfile
          className=""
          style={{ height: "30px", width: "30px" }}
        />
      ),
      name: "Personal Information",
      click: (event) => {},
    },
    {
      id: 2,
      icon: (
        <AiOutlineUnlock
          className=""
          style={{ height: "30px", width: "30px" }}
        />
      ),
      name: "Change Password",
      click: (event) => {},
    },
    {
      id: 3,
      icon: (
        <AiOutlineLogout
          className=""
          style={{ height: "30px", width: "30px" }}
        />
      ),
      name: "Logout",
      click: (event) => {},
    },
  ];

  return (
    <>
      {(isSidebarOpen || !isMobile) && (
        <div
          className={`sidebar tw-flex tw-flex-col tw-justify-between ${
            isMobile ? "mobile-sidebar" : ""
          }`}
        >
          <div>
            <div className="logo">
              <div className="logo-img">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "center",
                    marginLeft: "40px",
                  }}
                >
                  <div
                    style={{
                      width: "48px",
                      height: "48px",
                      marginRight: "10px",
                    }}
                  >
                    <img
                      src={logoImg}
                      alt="Logo"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <h5 style={{ fontFamily: "Poppins", margin: "0" }}>eWALLS</h5>
                </div>

                <hr />
              </div>
            </div>

            <div className="sidebar-wrapper" onClick={toggleSidebar}>
              {/* <Nav> */}
              <ul
                className="nav nav-pills flex-column mb-auto "
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                {filteredRoutes?.map((row, i) => {
                  const CheckIcon = iconMapping[row.icon] || FaList;
                  const IconComponent = CheckIcon;
                  return (
                    <li
                      className={`menu-list ${
                        row.path === selectedMenuItem ? "selected" : ""
                      } `}
                      key={i}
                    >
                      <a
                        href
                        onClick={() => handleIconClick(row.path)}
                        className="nav-NavLink"
                      >
                        <div className="icon">
                          <p
                            className="sidebar"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <IconComponent style={{ marginRight: "10px" }} />
                            {row.name}
                          </p>
                        </div>
                      </a>
                    </li>
                  );
                })}
              </ul>
              {/* </Nav> */}
            </div>
          </div>

          <div className="  tw-mt-8">
            <div className="tw-bottom-0 tw-left-0">
              {!loading && (
                <>
                  {/* Sidebar content */}
                  <ControlledOpenSelect
                    menuItem={businessData}
                    currentBusiness={currentBusiness}
                    userId={userId}
                    isAdmin={isUserAdmin}
                  />
                  <ProfileControlDrop
                    menuItem={profileFooterMenuItems}
                    defaultUser={defaultUser}
                    userId={userId}
                    onLogoutClick={() => {
                      setShowLogoutModal(true);
                    }}
                    isAdmin={isUserAdmin}
                  />
                  {/* Rest of your Sidebar content */}
                </>
              )}

              {loading && <div className="loader mb-1"></div>}
              <div className="tw-flex tw-items-center tw-font-bold tw-h-20  tw-justify-between tw-px-6  tw-bg-blue-500 tw-text-white">
                <Notification />
                <PiSquaresFourBold
                  aria-describedby={id}
                  onClick={handleClick}
                  className="tw-w-6 tw-h-6   tw-transition-colors tw-duration-300 tw-cursor-pointer "
                />
                <div
                  style={{
                    borderRadius: "2rem" /* 12px */,
                  }}
                >
                  <Popover
                    className="tw-rounded-3xl "
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    onClose={handleClose}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    style={{
                      borderRadius: "2rem" /* 12px */,
                    }}
                  >
                    <div className="tw-px-2.5 tw-py-5 tw-rounded-3xl ">
                      <div className="tw-w-full tw-rounded-xl tw-bg-white">
                        <a
                          href="https://ic.ewalls.io/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="tw-flex tw-items-center tw-gap-4 py-2 px-3 tw-border tw-border-b-0 tw-rounded-t-xl tw-border-blue-700 tw-bg-[#F3F3F3]">
                            <img
                              src={logoImg}
                              alt="Logo"
                              style={{ width: "40px", height: "40px" }}
                            />
                            <h1 className="tw-font-normal pt-1 tw-text-[25px] tw-text-gray-500 hover:tw-text-blue-700 tw-mr-20">
                              eWalls
                            </h1>
                          </div>
                        </a>
                        <a
                          href="https://development.d16u2ngtmfn3jt.amplifyapp.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="tw-flex tw-items-center tw-gap-4 py-2 px-3 tw-border tw-border-t-blue-700 tw-border-gray-300">
                            <img
                              src={smaakerlogo}
                              alt="Logo"
                              style={{ width: "40px", height: "40px" }}
                            />
                            <h1 className="tw-font-normal tw-text-[25px] tw-text-gray-500 hover:tw-text-orange-700">
                              Smaaker
                            </h1>
                          </div>
                        </a>
                        <a
                          href="https://master.dryav763x5nva.amplifyapp.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="tw-flex tw-items-center tw-gap-4 py-2 px-3 mr-10 tw-border tw-border-t-0 tw-border-gray-300 tw-rounded-b-xl">
                            <img
                              src={paylooplogo}
                              alt="Logo"
                              style={{ width: "40px", height: "40px" }}
                            />
                            <h1 className="tw-font-normal tw-text-[25px] tw-text-gray-500 hover:tw-text-green-700">
                              Payloop
                            </h1>
                          </div>
                        </a>
                      </div>
                    </div>
                  </Popover>
                </div>
                <button type="button" onClick={clickToOpenChatbot}>
                  {" "}
                  <PiChatsCircleBold className="tw-w-6 tw-h-6   tw-transition-colors tw-duration-300 " />
                </button>
                <RiSettings2Line className="tw-w-6 tw-h-6   tw-transition-colors tw-duration-300 " />
                <ChatBot
                  chatMenuState={chatMenuState}
                  setChatMenuState={setChatMenuState}
                  userId={userId}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Mobile toggle button */}
      {isMobile && (
        <Button
          type="button"
          onClick={toggleSidebar}
          style={{
            position: "absolute",
            top: 5,
            right: 10,
            fontSize: "20px",
            float: "right",
            padding: "0px 10px 0px 10px",
            backgroundColor: "#F5F7F8",
            color: "black",
            border: "none",
          }}
        >
          <AiOutlineMenuFold />
        </Button>
      )}

      <Modal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to logout? This action will sign you out of your
          account.
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "white" }}>
          <Button variant="secondary" onClick={() => setShowLogoutModal(false)}>
            No
          </Button>
          <Button variant="primary" onClick={handleLogout}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRoutes: state.userRoutes,
  userProfile: state.userProfile,
  Profileinfo: state.Profileinfo,
  currentBusiness: state.currentBusiness,
  accessToken: state.accessToken,
});

export default connect(mapStateToProps)(Sidebar);
