import React, { useState, useEffect } from "react";
import { AiOutlineEye, AiOutlineDelete } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { Modal, Col, Row } from "react-bootstrap";

import { Card, Avatar } from "antd";

import CustomButton from "../../../components/Button";
import { Toaster, toast } from "sonner";
import { setVideoFiles, setCurrentPage } from "../../../redux/action";
import CustomDataTable from "../../../components/DataTable";
import { apiClient } from "../../../components/apiClient";
import "./Videos.css";
import { DateFormat } from "../../../components/DateFormat";
import { connect } from "react-redux";
import { useFetchVideos } from "../../../hook/useFetchMedia";
import VideoNegativeScreens from "../../../components/NegativeScreens/Library/VideoNegativeScreen";

const { Meta } = Card;
const profilepic =
  "https://s3.amazonaws.com/creativetim_bucket/photos/264729/thumb.jpeg?1620116551";
const Videos = ({
  accessToken,
  videoFiles,
  view,
  searchTerm,
  currentPage,
  setCurrentPage,
  setVideoFiles,
  userProfile,
  sellerId,
  handleAddMoreClick,
}) => {
  const { data, isLoading, error } = useFetchVideos(
    accessToken,
    sellerId,
    searchTerm
  );

  const [showVideo, setShowVideo] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const columns = [
    {
      name: "Display videos",
      cell: (param) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <video src={param.fileUrl} width="100px" height="auto" controls />
        </div>
      ),
    },
    {
      name: "File Name",
      selector: (param) => param?.fileName,
    },
    {
      name: "Uploaded By",
      selector: () => userProfile?.name,
      sortable: true,
    },
    {
      name: "Date of Upload",
      selector: (param) => DateFormat(param?.createdAt),
      sortable: true,
    },
    {
      name: "File Size",
      selector: (param) => `${param?.size} MB`,
    },
    {
      name: "Action",
      cell: (param) => (
        <>
          {/* Edit Icon */}
          <span
            title="Edit"
            style={{
              cursor: "pointer",
              padding: "5px",
              display: "inline-block",
              border: "1px solid #DFDFDF",
              color: "#666666",
              background: "#F0F0F0",
              borderRadius: "4px",
            }}
            onClick={() => handleView(param)}
          >
            <div
              style={{
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AiOutlineEye style={{ fontSize: "20px", color: "#666666" }} />
            </div>
          </span>

          {/* Delete Icon */}
          <span
            title="Delete"
            style={{
              cursor: "pointer",
              padding: "5px",
              display: "inline-block",
              border: "1px solid #DFDFDF",
              color: "#666666",
              background: "#F0F0F0",
              borderRadius: "4px",
              marginLeft: "5px", // Adjust the spacing between icons
            }}
            onClick={() => handleDeleteClick(param.id)}
          >
            <div
              style={{
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AiOutlineDelete style={{ fontSize: "20px", color: "#666666" }} />
            </div>
          </span>
        </>
      ),
    },
  ];

  useEffect(() => {
    // console.log("videoFiles:", videoFiles);
    return () => {
      //  console.log("Cleanup function called.");
    };
  }, [videoFiles]);

  const handlePageChange = (selected) => {
    setCurrentPage(selected.selected);
  };

  const handleDeleteClick = async (mediaId) => {
    setFileToDelete(mediaId);
    setConfirmDeleteModal(true);
  };
  // const handleDelete = async (mediaId) => {
  //   setFileToDelete(mediaId);
  //   setConfirmDeleteModal(true);
  // };

  const handleConfirmDelete = async () => {
    try {
      const idToken = accessToken;
      const client = apiClient(idToken);

      const response = await client.delete(`library/mediafile/${fileToDelete}/${sellerId}`);

      const updatedVideoFiles = videoFiles.filter(
        (file) => file.id !== fileToDelete
      );
      setVideoFiles(updatedVideoFiles);

      if (response.status === 200) {
        toast.success("MediaFile Deleted successfully");

        // Update video files
        const videoResponse = await client.get(
          `library/mediaretrieveall/${sellerId}`,
          {
            params: {
              fileType: 2,
              filename: searchTerm,
            },
          }
        );
        if (Array.isArray(videoResponse?.data)) {
          setVideoFiles(videoResponse?.data);
        } else {
          console.error("Invalid video response format:", videoResponse);
        }
      } else {
        console.error("Error deleting media. Status:", response.status);
        toast.warning(
          "Media file is assigned to one or more layouts. Unassign them before deleting."
        );
      }

      return response.data;
    } catch (error) {
      console.error("Error deleting media:", error);
      toast.warning(
        "Media file is assigned to one or more layouts. Unassign them before deleting."
      );
    } finally {
      setConfirmDeleteModal(false);
      setShowModal(false);
    }
  };

  const handleView = (video) => {
    setSelectedVideo(video);
    setShowModal(true);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowVideo(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const itemsPerPage = 6;
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentVideoFiles = (
    searchResults?.length > 0 ? searchResults : videoFiles
  )?.slice(startIndex, endIndex);

  useEffect(() => {
    if (data) {
      setVideoFiles(data);
    }
  }, [data, setVideoFiles]);

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="videos-container">
      {videoFiles?.length > 0 && (
        <p className="parabelo-searchh">Library Files[{videoFiles?.length}]</p>
      )}

      {isLoading ? (
        <div className="loader"></div>
      ) : videoFiles?.length === 0 ? (
        <VideoNegativeScreens handleAddMoreClick={handleAddMoreClick} />
      ) : (
        <>
          {view === "list" ? (
            <div style={{ width: "100%" }}>
              <CustomDataTable
                columns={columns}
                data={videoFiles}
                pagination={false}
                tableKey="libraryVideosTable"
              />
            </div>
          ) : (
            <div className="video-grid">
              {currentVideoFiles?.map((video, index) => (
                <div
                  key={index}
                  className="video-card"
                  onMouseEnter={() => setHoveredCard(index)}
                  onMouseLeave={() => setHoveredCard(null)}
                >
                  {showVideo && (
                    <div className="react-player-wrapper">
                      <video
                        className="react-player"
                        width="100%"
                        height="100%"
                        controls
                      >
                        <source src={video?.fileUrl} type="video/mp4" />
                      </video>
                      {hoveredCard === index && (
                        <div
                          className="delete-button-container"
                          onClick={() => handleDeleteClick(video?.id)}
                        >
                          <AiOutlineDelete
                            style={{
                              color: "red",
                              backgroundColor: "#fff",
                              position: "absolute",
                              top: "10px",
                              right: "10px",

                              fontSize: "24px",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}

                  <div
                    style={{ padding: "10px" }}
                    className="content-below-pic"
                  >
                    <Card
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <Meta
                        avatar={<Avatar src={profilepic} />}
                        title={
                          <span
                            style={{ fontWeight: "500", fontFamily: "poppins" }}
                          >
                            {video?.fileName?.length < 16
                              ? video?.fileName
                              : video?.fileName.substring(0, 14)}
                            ... By {userProfile?.name}
                          </span>
                        }
                        description={
                          <div>
                            <p
                              className="creste-size-desc"
                              style={{ fontFamily: "poppins" }}
                            >
                              Created: {DateFormat(video?.createdAt)} | Size:{" "}
                              {video?.size} MB
                            </p>
                          </div>
                        }
                      />
                    </Card>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}

      {view !== "list" && (
        <div className="mt-3">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={Math.ceil(videoFiles?.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      )}

      <Modal
        size="lg"
        animation={false}
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <h4
              style={{
                fontSize: "16px",
                textAlign: "center",
                margin: 0,
                marginRight: "auto",
              }}
            >
              {selectedVideo && selectedVideo?.fileName}
            </h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          {selectedVideo && (
            <video
              src={selectedVideo?.fileUrl}
              alt={selectedVideo?.fileName}
              style={{ width: "100%" }}
              controls
            />
          )}
        </Modal.Body>
        <Modal.Footer style={{ background: "#fff" }}>
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {/* <CustomButton 
          BtnClassName="yes-modal-btn"
          ClickEvent={() => {
            setShowModal(false);
            setSelectedImage(null);
          }}
        /> */}
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        size="sm"
        animation
        show={confirmDeleteModal}
        onHide={() => setConfirmDeleteModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >
        <Modal.Header>
          <div style={{ width: "100%" }}>
            <h4 style={{ fontSize: "16px", textAlign: "center" }}>
              Do you want to delete it ?
            </h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row className="">
            <Col style={{ display: "flex", justifyContent: "space-around" }}>
              <CustomButton
                BtnTxt={<>Yes</>}
                BtnClassName="yes-modal-btn"
                ClickEvent={() => {
                  handleConfirmDelete();
                }}
              />
              <CustomButton
                BtnTxt={<>No</>}
                BtnClassName="yes-modal-btn"
                ClickEvent={() => setConfirmDeleteModal(false)}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Toaster position="bottom-right" className="tw-mr-4" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.accessToken,
  videoFiles: state.videomedia.videoFiles,
  currentPage: state.videomedia.currentPage,
  userProfile: state.userProfile,
  sellerId: state.sellerIdReducer,
});
const mapDispatchToProps = {
  setVideoFiles,
  setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Videos);
